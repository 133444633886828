import * as React from "react";
import pitchHref from "../assets/pitch.webp";
import { cn } from "@virtuallysports/vs-utils/dist/server";
import { GamesocketProvider } from "@virtuallysports/vs-utils/dist/client.mjs";

export interface ViewsProps {
  children: React.ReactNode;
}

export function Views({ children }: ViewsProps): JSX.Element {
  const [windowWidth, setWindowWidth] = React.useState(400);
  const targetRef = React.useRef<HTMLDivElement>(null);
  const { socket } = GamesocketProvider.useGamesocket();

  React.useEffect(() => {
    if (!window) return;
    getWindowWidth();
    window.addEventListener("resize", getWindowWidth);
    function getWindowWidth(): void {
      if (targetRef.current !== null) {
        setWindowWidth(targetRef.current.offsetWidth);
      }
    }
    return () => {
      window.removeEventListener("resize", getWindowWidth);
    };
  }, []);

  return (
    <div
      className={cn(
        "tw-z-0 tw-w-full tw-bg-white",
        windowWidth < 640 && "tw-my-0",
        windowWidth >= 640 && windowWidth < 768 && "tw-my-8",
        windowWidth >= 768 && windowWidth < 1024 && "tw-my-16",
        windowWidth >= 1024 && "tw-my-20"
      )}
      ref={targetRef}
      data-testid="test-views"
    >
      <div className="tw-mx-auto tw-max-w-2xl">
        <div className="tw-relative tw-h-full tw-w-full tw-pt-[44.44%]">
          {socket ? (
            children
          ) : (
            <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center ">
              <img
                src={pitchHref}
                className="tw-absolute tw-inset-0 tw-z-0 tw-h-full tw-w-full"
                alt="Soccer pitch"
              />
              <div className="tw-z-10 tw-flex tw-h-1/2 tw-w-full tw-items-center tw-justify-center tw-bg-black/50">
                <span className="tw-text-white">No connection</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
