import {
  CompetitorProvider,
  TrackedStatsProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";
import { ProbabilityBar } from "./ProbabilityBar";
import { StatsBar } from "./StatsBar";

export function Stats(): JSX.Element {
  const { competitors } = CompetitorProvider.useCompetitors();
  const { trackedStats } = TrackedStatsProvider.useTrackedStats();

  const homeTeam = competitors["home"];
  const awayTeam = competitors["away"];
  const homeStats = trackedStats["home"];
  const awayStats = trackedStats["away"];
  return (
    <>
      <div className="tw-absolute tw-inset-0 tw-grid tw-grid-cols-1 tw-grid-rows-5 tw-bg-popover tw-p-2">
        <div className="tw-row-span-1 tw-flex tw-items-center tw-justify-evenly">
          <StatsBar
            awayColor={awayTeam?.colour}
            awayStat={awayStats?.stats["attack"]}
            homeColor={homeTeam?.colour}
            homeStat={homeStats?.stats["attack"]}
            label="Attacks"
          />
          <StatsBar
            awayColor={awayTeam?.colour}
            awayStat={awayStats?.stats["shotOnTarget"]}
            homeColor={homeTeam?.colour}
            homeStat={homeStats?.stats["shotOnTarget"]}
            label="Shots on Target"
          />
        </div>
        <div className="tw-row-span-1 tw-flex tw-items-center tw-justify-evenly">
          <StatsBar
            awayColor={awayTeam?.colour}
            awayStat={awayStats?.stats["dangerousAttack"]}
            homeColor={homeTeam?.colour}
            homeStat={homeStats?.stats["dangerousAttack"]}
            label="Dangerous Attacks"
          />
          <StatsBar
            awayColor={awayTeam?.colour}
            awayStat={awayStats?.stats["shotOffTarget"]}
            homeColor={homeTeam?.colour}
            homeStat={homeStats?.stats["shotOffTarget"]}
            label="Shots off Target"
          />
        </div>
        <div className="tw-row-span-1 tw-flex tw-items-center tw-justify-center">
          <div className="tw-flex tw-h-full tw-w-1/2 tw-justify-center">
            <StatsBar
              awayColor={awayTeam?.colour}
              awayStat={awayStats?.stats["corner"]}
              homeColor={homeTeam?.colour}
              homeStat={homeStats?.stats["corner"]}
              label="Corners"
            />
          </div>
        </div>
        <div className="tw-row-span-2 tw-flex tw-items-center tw-justify-center">
          <ProbabilityBar label="Win Probability" />
        </div>
      </div>
    </>
  );
}
