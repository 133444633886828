import {
  GamesocketProvider,
  GametimeProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";
import {
  SOCCER_RUNNING,
  SOCCER_INJURY,
  parseGameStateIndex,
  SoccerEvent,
  secondsToMM,
} from "@virtuallysports/vs-utils/dist/server";

export function GameTimePill(): JSX.Element {
  const { socketData } = GamesocketProvider.useGamesocket() as {
    socketData: SoccerEvent;
  };
  const { gametime } = GametimeProvider.useGametime();

  return !socketData?.gameStateIndex ? (
    <div className="tw-mx-1 tw-w-14 tw-animate-pulse tw-rounded-full tw-bg-accent tw-p-2" />
  ) : (
    <div className="tw-flex tw-items-center tw-justify-center tw-space-x-0.5 tw-rounded-full tw-bg-accent tw-p-0.5 ">
      <div>
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0.166626 5.99996C0.166626 2.77996 2.77413 0.166626 5.99413 0.166626C9.21996 0.166626 11.8333 2.77996 11.8333 5.99996C11.8333 9.21996 9.21996 11.8333 5.99413 11.8333C2.77413 11.8333 0.166626 9.21996 0.166626 5.99996ZM1.33329 5.99996C1.33329 8.57829 3.42163 10.6666 5.99996 10.6666C8.57829 10.6666 10.6666 8.57829 10.6666 5.99996C10.6666 3.42163 8.57829 1.33329 5.99996 1.33329C3.42163 1.33329 1.33329 3.42163 1.33329 5.99996ZM5.41663 3.08329H6.29163V6.14579L8.91663 7.70329L8.47913 8.42079L5.41663 6.58329V3.08329Z"
            className="tw-fill-accent-foreground"
          />
        </svg>
      </div>
      <p className="tw-overflow-hidden tw-truncate tw-text-[11px] tw-text-accent-foreground">
        {parseGameStateIndex(socketData.gameStateIndex)}
      </p>
      <p className="tw-px-0.5 tw-text-[11px]">
        {socketData.gameStateIndex &&
        SOCCER_RUNNING.includes(socketData.gameStateIndex) ? (
          <span className="tw-font-semibold tw-text-accent-foreground">
            {`${secondsToMM(gametime)}'`}
          </span>
        ) : null}
        {socketData.gameStateIndex &&
        SOCCER_INJURY.includes(socketData.gameStateIndex) ? (
          <span className="tw-text-4xs tw-text-accent-foreground">+2</span>
        ) : null}
      </p>
    </div>
  );
}
