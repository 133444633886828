import { createRoot } from "react-dom/client";
import { AppWithProvider } from "./App";
import "./styles/reset.css";
import "./styles/tailwind.css";

const container = document.getElementById("sf-bk") as HTMLElement;
const root = createRoot(container);
const eventId = container.getAttribute("event-id");

root.render(<AppWithProvider eventID={eventId} />);
