import { SoccerEvent } from "@virtuallysports/vs-utils/dist/server";
import {
  ChannelInfoProvider,
  GamesocketProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";

export function LeaguePill(): JSX.Element {
  const { leagueName } = ChannelInfoProvider.useChannelInfo();
  const { socketData } = GamesocketProvider.useGamesocket() as {
    socketData: SoccerEvent;
  };
  return socketData?.gameStateIndex ? (
    <div className="tw-mx-1 tw-flex tw-items-center tw-rounded-full">
      <div className="tw-rounded-r-full ">
        <div className="tw-rounded-t-full tw-rounded-br-full tw-py-1 tw-text-foreground">
          <p className="tw-mt-0.5 tw-overflow-hidden tw-truncate">
            {leagueName}
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="tw-mx-1 tw-w-28 tw-animate-pulse tw-rounded-full tw-bg-muted-foreground tw-p-2" />
  );
}
