import { View, cn } from "@virtuallysports/vs-utils/dist/server";

export interface NavbarProps {
  showView: View | null;
  onClick: (showView: View | null) => void;
  disabled?: boolean;
}
export function Navbar({
  showView,
  onClick,
  disabled,
}: NavbarProps): JSX.Element {
  const isTable = Boolean(showView === "table");
  const isEvents = Boolean(showView === "events");
  const isStats = Boolean(showView === "stats");
  return (
    <div className="tw-z-10 tw-flex tw-w-full tw-items-center tw-justify-between tw-bg-background tw-px-3 tw-py-[5px]">
      {showView ? (
        <button
          className="tw-flex tw-items-center tw-space-x-2 tw-bg-transparent disabled:tw-opacity-50"
          onClick={() => {
            onClick(null);
          }}
          aria-label="close button"
          disabled={disabled}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            className="tw-fill-muted-foreground"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" />
          </svg>
          <span className="tw-text-[12px] tw-text-muted-foreground">Close</span>
        </button>
      ) : (
        <div />
      )}
      <div className="tw-flex tw-items-center tw-space-x-2">
        <button
          onClick={() => {
            onClick("events");
          }}
          className={cn(
            "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-p-[5px] disabled:tw-opacity-50",
            isEvents ? "tw-bg-secondary-foreground" : "tw-bg-transparent"
          )}
          aria-label="events button"
          disabled={disabled}
        >
          <svg
            width="23"
            height="16"
            viewBox="0 0 23 16"
            className={cn(
              isEvents ? "tw-fill-muted" : "tw-fill-muted-foreground"
            )}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 4.72713V1.27496C0 0.852148 0.352288 0.499878 0.775084 0.499878H10.5682V4.09313C8.59545 4.4454 7.0455 6.13621 7.0455 8.24983C7.0455 10.3635 8.59545 12.0543 10.5682 12.4065V15.9998H0.775084C0.352271 15.9998 0 15.6475 0 15.2247V11.7725H2.81817V4.72713H0Z" />
            <path d="M10.5682 10.9976C9.37054 10.6453 8.45459 9.58851 8.45459 8.24997C8.45459 6.91138 9.37054 5.85454 10.5682 5.57262V10.9976Z" />
            <path d="M14.0902 8.24993C14.0902 6.91139 13.1742 5.85458 11.9766 5.50231V10.9273C13.1742 10.6454 14.0902 9.58852 14.0902 8.24993Z" />
            <path d="M11.9766 12.4067C13.9493 12.0544 15.4993 10.3636 15.4993 8.24997C15.4993 6.13635 13.9493 4.44554 11.9766 4.09327V0.500019H21.7697C21.9811 0.500019 22.1925 0.570534 22.3334 0.711416C22.4742 0.852297 22.5448 1.06369 22.5448 1.27509V4.72726H19.7266V11.7727H22.5448V15.2248C22.5448 15.4362 22.4742 15.6476 22.3334 15.7885C22.1925 15.9294 21.9811 15.9999 21.7697 15.9999H11.9766V12.4067Z" />
            <path d="M0 10.3636V6.13631H1.40908V10.3636H0Z" />
            <path d="M21.1362 6.13631V10.3636H22.5453V6.13631H21.1362Z" />
          </svg>
        </button>
        <button
          onClick={() => {
            onClick("stats");
          }}
          className={cn(
            "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-p-[5px] disabled:tw-opacity-50",
            isStats ? "tw-bg-secondary-foreground" : "tw-bg-transparent"
          )}
          aria-label="stats button"
          disabled={disabled}
        >
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill={isStats ? "#fafafa" : "#000c2d60"}
            className={cn(
              isStats ? "tw-fill-muted" : "tw-fill-muted-foreground"
            )}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 4.2H3V14H0V4.2ZM5.6 0H8.4V14H5.6V0ZM11.2 8H14V14H11.2V8Z" />
          </svg>
        </button>
        <button
          onClick={() => {
            onClick("table");
          }}
          className={cn(
            "tw-flex tw-h-8 tw-w-8 tw-items-center tw-justify-center tw-rounded-full tw-p-[5px] disabled:tw-opacity-50",
            isTable ? "tw-bg-secondary-foreground" : "tw-bg-transparent"
          )}
          aria-label="table button"
          disabled={disabled}
        >
          <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill={isTable ? "#fafafa" : "#000c2d60"}
            className={cn(
              isTable ? "tw-fill-muted" : "tw-fill-muted-foreground"
            )}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M16 2V16H2V2H16ZM17.1 0H0.9C0.4 0 0 0.4 0 0.9V17.1C0 17.5 0.4 18 0.9 18H17.1C17.5 18 18 17.5 18 17.1V0.9C18 0.4 17.5 0 17.1 0ZM8 4H14V6H8V4ZM8 8H14V10H8V8ZM8 12H14V14H8V12ZM4 4H6V6H4V4ZM4 8H6V10H4V8ZM4 12H6V14H4V12Z" />
          </svg>
        </button>
      </div>
    </div>
  );
}
