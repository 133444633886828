import pitch from "../assets/pitch.webp";
export const WidgetSkeleton = () => {
  return (
    <div className="tw-w-full tw-h-full tw-bg-gray-100 tw-animate-pulse">
      <div className="tw-h-24 tw-border-b-2 tw-border-border tw-px-2 tw-py-3 tw-flex tw-flex-col tw-justify-between">
        <div className="tw-h-[18px] tw-w-20 tw-bg-accent tw-rounded-full"></div>
        <div className="tw-flex tw-w-full tw-justify-center">
          <span>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0001 0.599976C3.47179 0.599976 0.600098 3.47167 0.600098 6.99998C0.600098 10.5283 3.47179 13.4 7.0001 13.4C10.5284 13.4 13.4001 10.5283 13.4001 6.99998C13.4001 3.47167 10.5284 0.599976 7.0001 0.599976ZM5.22301 1.97498L7.0001 3.26664L8.77718 1.97498C9.76014 2.32136 10.6085 2.9449 11.2303 3.75518L10.5511 5.84685L12.3261 7.13643C12.2992 8.20642 11.9632 9.19695 11.398 10.0198H9.19385L8.51468 12.1125C8.0345 12.2539 7.52691 12.3333 7.0001 12.3333C6.47329 12.3333 5.9657 12.2539 5.48551 12.1125L4.80531 10.0208H2.60322C2.03751 9.19775 1.70106 8.20694 1.67406 7.13643L3.4501 5.84581L2.76989 3.75518C3.39173 2.9449 4.24006 2.32136 5.22301 1.97498ZM7.0001 4.33331L4.47614 6.16664L5.44072 9.13331H8.55947L9.52406 6.16664L7.0001 4.33331Z"
                className="tw-fill-muted-foreground"
              />
            </svg>
          </span>
        </div>
      </div>
      <div className="tw-w-full tw-h-[200px]">
        <img src={pitch} className="tw-w-full tw-h-full tw-object-cover" />
      </div>
      <div className="tw-w-full tw-h-11 tw-flex tw-justify-between tw-items-center tw-px-3 tw-py-[5px]">
        <div className="tw-w-8 tw-h-8 tw-bg-border tw-rounded-full" />
        <div className="tw-flex tw-gap-2">
          <div className="tw-w-8 tw-h-8 tw-bg-border tw-rounded-full" />
          <div className="tw-w-8 tw-h-8 tw-bg-border tw-rounded-full" />
          <div className="tw-w-8 tw-h-8 tw-bg-border tw-rounded-full" />
        </div>
      </div>
    </div>
  );
};
