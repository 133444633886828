import * as React from "react";
import {
  CompetitorProviderActionTypes,
  Config,
  MarketProviderActionTypes,
  parseSoccerProviderGameState,
  SoccerGoalTimesProviderActionTypes,
  TrackedStatsProviderActionTypes,
  View,
} from "@virtuallysports/vs-utils/dist/server";
import {
  Events,
  LeagueTable,
  Navbar,
  Scoreboard,
  Stats,
  Views,
  WidgetSkeleton,
} from "./components";
import {
  ChannelInfoProvider,
  CompetitorProvider,
  ConfigProvider,
  GamesocketProvider,
  GameStateProvider,
  GametimeProvider,
  MarketProvider,
  OutcomeProvider,
  SoccerGoalTimesProvider,
  TableProvider,
  TrackedStatsProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";

export interface AppProps {
  eventID: string | null;
}

const CONFIG: Config = {
  animationDelay: 4000,
  commentaryDelay: 2000,
  supportedLocales: ["en"],
  collapsedStatsConfigs: ["Dangerous attack", "Shot on target", "Corner"],
  mainMarketCode: 1001,
  url: "api.vslinternal9522.com/api/v1",
};

function App() {
  const [showView, setShowView] = React.useState<View | null>("events");
  const { socketData } = GamesocketProvider.useGamesocket();
  const { config } = ConfigProvider.useConfig();
  const { dispatch: dispatchCompetitors } = CompetitorProvider.useCompetitors();
  const { dispatch: dispatchGoaltime } =
    SoccerGoalTimesProvider.useSoccerGoalTimes();
  const { dispatch: dispatchTrackedStats } =
    TrackedStatsProvider.useTrackedStats();
  const { currentOutcome } = OutcomeProvider.useOutcome();
  const { dispatch: dispatchMarket } = MarketProvider.useMarket();

  React.useEffect(() => {
    if (!config.mainMarketCode) return;
    const mainMarket = socketData?.markets[config.mainMarketCode];
    if (!mainMarket) return;

    dispatchMarket({
      type: MarketProviderActionTypes.SET_MARKET_STATUS,
      payload: mainMarket.status,
    });
    dispatchMarket({
      type: MarketProviderActionTypes.SET_PROBABILITIES,
      payload: mainMarket.choices,
    });
  }, [dispatchMarket, config?.mainMarketCode, socketData?.markets]);

  React.useEffect(() => {
    if (!currentOutcome || !socketData?.sportType) return;
    dispatchTrackedStats({
      type: TrackedStatsProviderActionTypes.UPDATE_STAT,
      payload: { outcome: currentOutcome, sportType: socketData.sportType },
    });
    if (currentOutcome === "HGOL" || currentOutcome === "AGOL") {
      dispatchGoaltime({
        type: SoccerGoalTimesProviderActionTypes.UPDATE_GOAL_TIMES,
      });
    }
  }, [currentOutcome, socketData?.sportType, dispatchTrackedStats]);

  React.useEffect(() => {
    if (!socketData?.results) return;
    dispatchGoaltime({
      type: SoccerGoalTimesProviderActionTypes.SET_GOAL_TIMES,
      payload: socketData.results,
    });
  }, [dispatchGoaltime, socketData?.results]);

  React.useEffect(() => {
    if (!socketData?.results || !socketData?.sportType) return;
    dispatchTrackedStats({
      type: TrackedStatsProviderActionTypes.SET_TRACKED_STATS,
      payload: { results: socketData.results, sportType: socketData.sportType },
    });
  }, [dispatchTrackedStats, socketData?.results, socketData?.sportType]);

  React.useEffect(() => {
    if (!socketData?.competitors || !socketData.sportType) return;
    dispatchCompetitors({
      type: CompetitorProviderActionTypes.SET_COMPETITORS,
      payload: {
        competitors: socketData.competitors,
        sportType: socketData.sportType,
      },
    });
  }, [socketData?.competitors, socketData?.sportType, dispatchCompetitors]);

  const handleShowView = (value: View | null) => {
    if (value === null) {
      setShowView(null);
    } else {
      setShowView(value);
    }
  };

  return (
    <TableProvider
      competitionId={socketData?.competitionId ?? null}
      url={config.url}
    >
      <div className="tw-rounded-b-sm tw-bg-popover tw-shadow-lg">
        <Scoreboard />
        <TableProvider
          competitionId={socketData?.competitionId ?? null}
          url={config.url}
        >
          {showView ? (
            <Views>
              {showView === "events" ? <Events /> : null}
              {showView === "stats" ? <Stats /> : null}
              {showView === "table" ? <LeagueTable /> : null}
            </Views>
          ) : null}
        </TableProvider>
        <Navbar
          disabled={!socketData}
          onClick={(value) => handleShowView(value)}
          showView={showView}
        />
      </div>
    </TableProvider>
  );
}

export function AppWithProvider({ eventID }: AppProps) {
  if (!eventID) return <WidgetSkeleton />;
  return (
    <ConfigProvider config={CONFIG}>
      <GamesocketProvider eventID={eventID}>
      <GameStateProvider gameStateParser={parseSoccerProviderGameState}>
        <CompetitorProvider>
          <OutcomeProvider>
            <ChannelInfoProvider>
              <TrackedStatsProvider>
                <SoccerGoalTimesProvider>
                  <GametimeProvider countUp={true}>
                    <MarketProvider>
                      <App />
                    </MarketProvider>
                  </GametimeProvider>
                </SoccerGoalTimesProvider>
              </TrackedStatsProvider>
            </ChannelInfoProvider>
          </OutcomeProvider>
        </CompetitorProvider>
        </GameStateProvider>
      </GamesocketProvider>
    </ConfigProvider>
  );
}
