import {
  type SoccerTableStats,
  cn,
} from "@virtuallysports/vs-utils/dist/server";
import {
  type ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  TableProvider,
  useSoccerTable,
} from "@virtuallysports/vs-utils/dist/client.mjs";

export function LeagueTable() {
  const { data } = TableProvider.useTable();
  const table = useSoccerTable(data);

  return <DataTable columns={soccerColumns} data={table} />;
}

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
}

export function DataTable<TData, TValue>({
  columns,
  data,
}: DataTableProps<TData, TValue>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="tw-absolute tw-inset-0 tw-h-full tw-w-full tw-overflow-y-auto tw-overflow-x-hidden tw-scrollbar-thin tw-scrollbar-track-secondary tw-scrollbar-thumb-background">
      <table className="tw-h-full tw-w-full tw-rounded-md ">
        <thead className="tw-sticky -tw-top-[1px] tw-h-1/6 ">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr
              key={headerGroup.id}
              className=" tw-bg-muted tw-text-left tw-text-2xs tw-text-muted-foreground"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <th key={header.id} className="tw-px-1">
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody className="tw-divide-y tw-divide-border tw-border tw-border-border ">
          {table.getRowModel().rows.map((row, idx) => (
            <tr
              key={row.id}
              className={cn(
                " tw-text-2xs tw-text-foreground",
                idx % 2 === 0 ? "tw-bg-background" : "tw-bg-muted"
              )}
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id} className="tw-px-1">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const soccerColumns: ColumnDef<SoccerTableStats>[] = [
  {
    accessorKey: "name",
    header: "LEAGUE TABLE",
  },
  {
    accessorKey: "gamesPlayed",
    header: "P",
  },
  {
    accessorKey: "wins",
    header: "W",
  },
  {
    accessorKey: "draws",
    header: "D",
  },
  {
    accessorKey: "losses",
    header: "L",
  },
  {
    accessorKey: "goalsFor",
    header: "F",
  },
  {
    accessorKey: "goalsAgainst",
    header: "A",
  },
  {
    accessorKey: "goalDiff",
    header: "+/-",
  },
  {
    accessorKey: "points",
    header: "Pts",
  },
];
