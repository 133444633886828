interface StatsBarProps {
  awayColor?: string;
  awayStat: number;
  homeColor?: string;
  homeStat: number;
  label: string;
}

export function StatsBar({
  awayColor = "#1263DD",
  awayStat,
  homeColor = "#DD122A",
  homeStat,
  label,
}: StatsBarProps): JSX.Element {
  let home = homeStat;
  let away = awayStat;
  let denom = homeStat + awayStat;
  if (homeStat === 0 && awayStat === 0) {
    home = 1;
    away = 1;
    denom = 2;
  }
  return (
    <div className="tw-mx-[18px] tw-flex tw-w-full tw-flex-1 tw-flex-col tw-items-center tw-text-[11px]">
      <p className="tw-overflow-hidden tw-truncate tw-text-center tw-text-2xs tw-font-bold tw-leading-none tw-text-foreground">
        {label}
      </p>
      <div className="tw-flex tw-w-full tw-flex-1 tw-items-center tw-justify-between">
        <p className="tw-text-foreground">{homeStat}</p>
        <div className="tw-mx-1 tw-flex tw-h-2 tw-w-full tw-flex-1 tw-overflow-hidden tw-rounded-full tw-shadow">
          <div
            style={{
              width: `${(home / denom) * 100}%`,
              backgroundColor: homeColor,
            }}
            className={`tw-h-full tw-rounded-l-full`}
            aria-label={`home ${label} stat percentage`}
          />
          <div
            style={{
              width: `${(away / denom) * 100}%`,
              backgroundColor: awayColor,
            }}
            className={`tw-h-full tw-rounded-r-full`}
            aria-label={`away ${label} stat percentage`}
          />
        </div>
        <p className="tw-text-foreground">{awayStat}</p>
      </div>
    </div>
  );
}
