import { cn } from "@virtuallysports/vs-utils/dist/server";
import { HTMLAttributes } from "react";

export interface ClockProps extends HTMLAttributes<HTMLDivElement> {
  time: string;
  className?: string;
  icon?: React.ReactNode;
}

export function Clock({ time, className, icon }: ClockProps) {
  return (
    <div
      className={cn(
        "tw-bg-accent tw-rounded-b-md tw-w-12 tw-flex tw-justify-center tw-items-center tw-py-1",
        className
      )}
    >
      {icon ? icon : null}
      <span className="tw-text-xs tw-text-accent-foreground">{time}</span>
    </div>
  );
}
