import {
  CompetitorProvider,
  SoccerGoalTimesProvider,
  TrackedStatsProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";
import { GameTimePill } from "./GameTimePill";
import { LeaguePill } from "./LeaguePill";

export function Scoreboard() {
  const { competitors } = CompetitorProvider.useCompetitors();
  const { trackedStats } = TrackedStatsProvider.useTrackedStats();
  const { goalTimes } = SoccerGoalTimesProvider.useSoccerGoalTimes();
  const homeTeam = competitors["home"];
  const awayTeam = competitors["away"];
  const homeStats = trackedStats["home"];
  const awayStats = trackedStats["away"];
  const homeGoalTimes = goalTimes["home"];
  const awayGoalTimes = goalTimes["away"];
  return (
    <div className="tw-z-10 tw-w-full tw-rounded-t-lg tw-bg-background tw-px-2 tw-py-3">
      <div className="tw-flex tw-flex-col tw-justify-start">
        <div className="tw-mb-4 tw-flex tw-h-[18px] tw-text-xs">
          <GameTimePill />
          <LeaguePill />
        </div>

        <div className="tw-mb-1 tw-flex tw-items-center tw-justify-center tw-text-sm ">
          <div className="tw-flex tw-flex-1 tw-items-center tw-justify-end tw-overflow-hidden tw-truncate">
            {homeTeam ? (
              <p className="tw-overflow-hidden tw-truncate tw-text-[14px] tw-font-bold tw-text-foreground">
                {homeTeam.name}
              </p>
            ) : (
              <div className="tw-w-20 tw-animate-pulse tw-rounded-full tw-bg-muted-foreground tw-p-1" />
            )}
          </div>
          <div className="tw-mx-[7px] tw-flex tw-items-center tw-text-[14px] tw-font-bold tw-text-accent">
            <span className="tw-w-[24px] tw-text-center">
              {homeStats?.stats.goal ?? 0}
            </span>
            <span className="tw-mx-1 tw-text-foreground">-</span>
            <span className="tw-w-[24px] tw-text-center">
              {awayStats?.stats.goal ?? 0}
            </span>
          </div>
          <div className="tw-flex tw-flex-1 tw-items-center tw-overflow-hidden tw-truncate">
            {awayTeam ? (
              <p className="tw-overflow-hidden tw-truncate tw-text-[14px] tw-font-bold tw-text-foreground">
                {awayTeam.name}
              </p>
            ) : (
              <div className="tw-w-20 tw-animate-pulse tw-rounded-full tw-bg-muted-foreground tw-p-1" />
            )}
          </div>
        </div>
      </div>

      <div className="tw-flex tw-items-center tw-justify-center">
        <ul className="tw-flex tw-flex-1 tw-items-center tw-justify-end">
          {homeGoalTimes.goalTimes.length > 0
            ? homeGoalTimes.goalTimes.map((time, idx) => (
                <li
                  key={idx}
                  className="tw-mx-[2px] tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-popover tw-p-0.5 tw-font-semibold tw-shadow"
                >
                  <span className="tw-whitespace-nowrap tw-text-[9px] tw-font-semibold tw-text-foreground">{`${time.gameMinute}'`}</span>
                </li>
              ))
            : null}
        </ul>
        <div className="tw-mx-2 tw-p-0.5">
          <span>
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.0001 0.599976C3.47179 0.599976 0.600098 3.47167 0.600098 6.99998C0.600098 10.5283 3.47179 13.4 7.0001 13.4C10.5284 13.4 13.4001 10.5283 13.4001 6.99998C13.4001 3.47167 10.5284 0.599976 7.0001 0.599976ZM5.22301 1.97498L7.0001 3.26664L8.77718 1.97498C9.76014 2.32136 10.6085 2.9449 11.2303 3.75518L10.5511 5.84685L12.3261 7.13643C12.2992 8.20642 11.9632 9.19695 11.398 10.0198H9.19385L8.51468 12.1125C8.0345 12.2539 7.52691 12.3333 7.0001 12.3333C6.47329 12.3333 5.9657 12.2539 5.48551 12.1125L4.80531 10.0208H2.60322C2.03751 9.19775 1.70106 8.20694 1.67406 7.13643L3.4501 5.84581L2.76989 3.75518C3.39173 2.9449 4.24006 2.32136 5.22301 1.97498ZM7.0001 4.33331L4.47614 6.16664L5.44072 9.13331H8.55947L9.52406 6.16664L7.0001 4.33331Z"
                className="tw-fill-muted-foreground"
              />
            </svg>
          </span>
        </div>
        <ul className="tw-flex tw-flex-1 tw-items-center tw-justify-self-end">
          {awayGoalTimes.goalTimes.length > 0
            ? awayGoalTimes.goalTimes.map((time, idx) => (
                <li
                  key={idx}
                  className="tw-mx-[2px] tw-flex tw-h-5 tw-w-5 tw-items-center tw-justify-center tw-rounded-full tw-bg-popover tw-p-0.5 tw-shadow"
                >
                  <span className="tw-whitespace-nowrap  tw-text-[9px] tw-font-semibold tw-text-foreground">
                    {`${time.gameMinute}'`}
                  </span>
                </li>
              ))
            : null}
        </ul>
      </div>
    </div>
  );
}
