import {
  CompetitorProvider,
  MarketProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";
import { cn } from "@virtuallysports/vs-utils/dist/server";

export function ProbabilityBar({ label }: { label: string }): JSX.Element {
  const { market } = MarketProvider.useMarket();
  const { competitors } = CompetitorProvider.useCompetitors();

  const homeTeam = competitors["home"];
  const awayTeam = competitors["away"];

  return (
    <div className="tw-relative tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-evenly tw-px-4 tw-pt-2 tw-text-[11px]">
      <div className="tw-flex tw-w-full tw-items-center tw-justify-between tw-text-center">
        <div className="tw-relative tw-flex tw-w-1/3 tw-items-center tw-justify-start">
          <p
            className="tw-ml-1 tw-overflow-hidden tw-truncate tw-whitespace-nowrap tw-text-foreground"
            aria-label="home team"
          >
            {homeTeam?.name}
          </p>
        </div>
        <p className="tw-mb-2 tw-w-1/3 tw-overflow-hidden tw-truncate tw-text-center tw-font-bold tw-text-foreground">
          {label}
        </p>
        <div className="tw-flex tw-w-1/3 tw-items-center tw-justify-end">
          <p
            className="tw-mr-1 tw-overflow-hidden tw-truncate tw-whitespace-nowrap tw-text-foreground"
            aria-label="away team"
          >
            {awayTeam?.name}
          </p>
        </div>
      </div>

      <div className="tw-flex tw-w-full tw-items-center tw-justify-center">
        <div className="tw-flex tw-h-2 tw-w-full tw-items-center tw-rounded-full tw-shadow">
          {market.marketStatus === "Opened" ? (
            <>
              <div
                className="tw-h-full tw-rounded-l-full"
                style={{
                  backgroundColor: homeTeam?.colour,
                  width: `${market.probabilities[1]?.probability}%`,
                }}
              />
              <div
                className={cn(
                  "tw-relative tw-flex tw-h-full tw-items-center tw-justify-center tw-bg-secondary tw-shadow",
                  Number(market.probabilities[1]?.probability) <= 0 &&
                    "tw-rounded-l-full",
                  Number(market.probabilities[3]?.probability) <= 0 &&
                    "tw-rounded-r-full"
                )}
                style={{
                  width: `${market.probabilities[2]?.probability}%`,
                }}
              >
                <p className="tw-flex tw-items-center tw-justify-center tw-space-x-1 tw-whitespace-nowrap tw-rounded-full tw-bg-secondary tw-px-1.5 tw-text-3xs tw-font-bold tw-text-secondary-foreground tw-shadow">
                  {Number(market.probabilities[2]?.probability) > 7 ? (
                    <span>Draw</span>
                  ) : null}
                  <span>{market.probabilities[2]?.probability}%</span>
                </p>
              </div>
              <div
                className="tw-h-full tw-rounded-r-full"
                style={{
                  backgroundColor: awayTeam?.colour,
                  width: `${market.probabilities[3]?.probability}%`,
                }}
              />
            </>
          ) : (
            <div className="tw-h-full tw-w-full tw-rounded-full tw-bg-secondary" />
          )}
        </div>
      </div>

      <div className="tw-flex tw-w-full tw-items-center tw-justify-between">
        {market.marketStatus === "Opened" ? (
          <>
            <p
              aria-label="home team win probability"
              className="tw-ml-1 tw-text-foreground"
            >
              {market.probabilities[1]?.probability}%
            </p>
            <p
              aria-label="away team win probability"
              className="tw-mr-1 tw-text-foreground"
            >
              {market.probabilities[3]?.probability}%
            </p>
          </>
        ) : (
          <div />
        )}
      </div>
    </div>
  );
}
