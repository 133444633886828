import * as React from "react";
import { AnimatePresence, motion, type Transition } from "framer-motion";
import {
  SOCCER_RUNNING,
  SOCCER_NOT_RUNNING,
  SOCCER_HOME_CODES,
  SOCCER_AWAY_CODES,
  cn,
  SoccerEvent,
} from "@virtuallysports/vs-utils/dist/server";
import {
  CompetitorProvider,
  GamesocketProvider,
  TrackedStatsProvider,
} from "@virtuallysports/vs-utils/dist/client.mjs";

const LEFT_CODES = [
  "APOS",
  "AATT",
  "ADAN",
  "ASOF",
  "ASON",
  "ACOR",
  "AOFF",
  "AFOU",
];

const CENTER_CODES = ["HTHR", "HGKK", "ATHR", "AGKK"];
const RIGHT_CODES = ["HPOS", "HCOR"];

const POLY_CODES = [
  "HPOS",
  "HATT",
  "HDAN",
  "HSOF",
  "HSON",
  "APOS",
  "AATT",
  "ADAN",
  "ASOF",
  "ASON",
];
const TRANSITION: Transition = { ease: "linear", duration: 0.5 };

const TEXT_VARIANT = {
  HPOS: { height: "100%", width: "50%", left: "0%", right: "50%", opacity: 1 },
  HATT: { height: "100%", width: "60%", left: "0%", right: "40%", opacity: 1 },
  HDAN: { height: "100%", width: "70%", left: "0%", right: "30%", opacity: 1 },
  HSOF: { height: "100%", width: "85%", left: "0%", right: "15%", opacity: 1 },
  HSON: { height: "100%", width: "90%", left: "0%", right: "10%", opacity: 1 },
  HGKK: { height: "100%", width: "60%", left: "35%", right: "5%", opacity: 1 },
  HCOR: {
    height: "100%",
    width: "80%",
    left: "0%",
    right: "20%",
    opacity: 1,
  },
  HTHR: {
    height: "100%",
    width: "50%",
    left: "0%",
    right: "20%",
    opacity: 1,
  },
  HOFF: {
    height: "100%",
    width: "75%",
    left: "0%",
    right: "25%",
    opacity: 1,
    borderRight: "2px dashed white",
    alignItems: "end",
  },
  HFOU: {
    height: "100%",
    width: "40%",
    left: "0%",
    right: "60%",
    opacity: 1,
    alignItems: "end",
  },
  APOS: { height: "100%", width: "50%", left: "50%", right: "0%", opacity: 1 },
  ACOR: {
    height: "100%",
    width: "80%",
    left: "20%",
    right: "0%",
    opacity: 1,
  },
  ATHR: {
    height: "100%",
    width: "50%",
    left: "50%",
    right: "0%",
    opacity: 1,
  },
  AATT: { height: "100%", width: "60%", left: "40%", right: "0%", opacity: 1 },
  ADAN: { height: "100%", width: "70%", left: "30%", right: "0%", opacity: 1 },
  ASOF: { height: "100%", width: "85%", left: "15%", right: "0%", opacity: 1 },
  ASON: { height: "100%", width: "90%", left: "10%", right: "0%", opacity: 1 },
  AGKK: { height: "100%", width: "60%", left: "5%", right: "35%", opacity: 1 },
  AOFF: {
    height: "100%",
    width: "75%",
    left: "25%",
    right: "0%",
    opacity: 1,
    borderLeft: "2px dashed white",
    alignItems: "start",
  },
  AFOU: {
    height: "100%",
    width: "40%",
    left: "60%",
    right: "0%",
    opacity: 1,
    alignItems: "start",
  },
  HID: { height: "0%", left: "0", right: "0", opacity: 0 },
};

const POLYGONE_VARIANTS = {
  HPOS: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 50% 0%, 50% 16.67%, 50% 33.33%, 50% 50%, 50% 66.67%, 50% 83.33%, 50% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  HCOR: {
    width: "100%",
    clipPath:
      "polygon(100% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 100% 100%, 100% 83.33%, 100% 66.67%, 100% 50%, 100% 33.33%, 100% 16.67%)",
  },
  HTHR: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 50% 0%, 50% 16.67%, 50% 33.33%, 50% 50%, 50% 66.67%, 50% 83.33%, 50% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  HATT: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 60% 0%, 63.33% 16.67%, 66.67% 33.33%, 70% 50%, 66.67% 66.67%, 63.33% 83.33%, 60% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  HDAN: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 80% 0%, 85% 16.67%, 90% 33.33%, 95% 50%, 90% 66.67%, 85% 83.33%, 80% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  HSOF: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 90% 0%, 95% 25%, 95% 25%, 90% 50%, 95% 75%, 95% 75%, 90% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  HSON: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 92.5% 0%, 95% 16.67%, 92.5% 33.33%, 95% 50%, 92.5% 66.67%, 95% 83.33%, 92.5% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  APOS: {
    width: "100%",
    clipPath:
      "polygon(50% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 50% 100%, 50% 83.33%, 50% 66.67%, 50% 50%, 50% 33.33%, 50% 16.67%)",
  },
  ACOR: {
    width: "100%",
    clipPath:
      "polygon(0% 0%, 0% 0%, 0% 16.67%, 0% 33.33%, 0% 50%, 0% 66.67%, 0% 83.33%, 0% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
  AATT: {
    width: "100%",
    clipPath:
      "polygon(40% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 40% 100%, 36.67% 83.33%, 33.33% 66.67%, 30% 50%, 33.33% 33.33%, 36.67% 16.67%)",
  },
  ADAN: {
    width: "100%",
    clipPath:
      "polygon(20% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 20% 100%, 15% 83.33%, 10% 66.67%, 5% 50%, 10% 33.33%, 15% 16.67%)",
  },
  ASOF: {
    width: "100%",
    clipPath:
      "polygon(10% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 10% 100%, 5% 75%, 5% 75%, 10% 50%, 5% 25%, 5% 25%)",
  },
  ASON: {
    width: "100%",
    clipPath:
      "polygon(7.5% 0%, 100% 0%, 100% 16.67%, 100% 33.33%, 100% 50%, 100% 66.67%, 100% 83.33%, 100% 100%, 7.5% 100%, 5% 83.33%, 7.5% 66.67%, 5% 50%, 7.5% 33.33%, 5% 16.67%)",
  },
  HID: {
    clipPath:
      "polygon(0% 0%, 0% 0%, 0% 16.67%, 0% 33.33%, 0% 50%, 0% 66.67%, 0% 83.33%, 0% 100%, 0% 100%, 0% 83.33%, 0% 66.67%, 0% 50%, 0% 33.33%, 0% 16.67%)",
  },
};

interface EventsOverlayProps {
  currentAnimationCode: string | null;
}

export function EventsOverlay({
  currentAnimationCode,
}: EventsOverlayProps): JSX.Element | null {
  const { socketData } = GamesocketProvider.useGamesocket() as {
    socketData: SoccerEvent;
  };
  const { competitors } = CompetitorProvider.useCompetitors();

  if (!socketData?.gameStateIndex) return null;
  const isRunning = SOCCER_RUNNING.includes(socketData?.gameStateIndex);
  const isHomeAnimation = SOCCER_HOME_CODES.includes(
    currentAnimationCode ?? ""
  );
  const isLeftAlign = Boolean(LEFT_CODES.includes(currentAnimationCode ?? ""));
  const isRightAlign = Boolean(
    RIGHT_CODES.includes(currentAnimationCode ?? "")
  );
  const isCenterAlign = Boolean(
    CENTER_CODES.includes(currentAnimationCode ?? "")
  );
  const homeTeam = competitors["home"];
  const awayTeam = competitors["away"];

  if (isRunning) {
    return (
      <LiveAnimation
        teamName={isHomeAnimation ? homeTeam?.name : awayTeam?.name}
        teamColour={isHomeAnimation ? homeTeam?.colour : awayTeam?.colour}
        leftAlign={isLeftAlign}
        rightAlign={isRightAlign}
        centerAlign={isCenterAlign}
        isHome={isHomeAnimation}
        currentAnimationCode={currentAnimationCode}
      />
    );
  }
  return <GameState />;
}

interface AnimationProps {
  teamName: string;
  teamColour: string;
  leftAlign?: boolean;
  centerAlign?: boolean;
  rightAlign?: boolean;
  isHome?: boolean;
  currentAnimationCode: string | null;
}

function LiveAnimation({
  teamName,
  teamColour,
  leftAlign,
  centerAlign,
  rightAlign,
  isHome,
  currentAnimationCode,
}: AnimationProps): JSX.Element {
  const [overlayText, setOverlayText] = React.useState<string>("");

  React.useEffect(() => {
    switch (currentAnimationCode) {
      case "HPOS":
      case "APOS":
        setOverlayText("Possession");
        break;
      case "HATT":
      case "AATT":
        setOverlayText("Attacking");
        break;
      case "HDAN":
      case "ADAN":
        setOverlayText("Dangerous Attack");
        break;
      case "HSOF":
      case "ASOF":
        setOverlayText("Shot off Target");
        break;
      case "HSON":
      case "ASON":
        setOverlayText("Shot on Target");
        break;
      case "ACOR":
      case "HCOR":
        setOverlayText("Corner");
        break;
      case "ATHR":
      case "HTHR":
        setOverlayText("Throw-in");
        break;
      case "AGKK":
      case "HGKK":
        setOverlayText("Goal kick");
        break;
      case "AOFF":
      case "HOFF":
        setOverlayText("Offside");
        break;
      case "AFOU":
      case "HFOU":
        setOverlayText("Foul");
        break;
      default:
        setOverlayText("");
    }
  }, [currentAnimationCode]);

  return (
    <motion.div className="tw-relative tw-h-full tw-w-full ">
      <motion.div
        className={cn(
          "tw-absolute tw-z-20 tw-flex tw-h-full tw-flex-col tw-justify-center tw-p-2 tw-text-secondary-foreground",
          leftAlign && "tw-items-start",
          rightAlign && "tw-items-end",
          centerAlign && "tw-items-center"
        )}
        variants={TEXT_VARIANT}
        animate={currentAnimationCode ?? undefined}
        initial={{ height: "0%", width: "100%", opacity: 0 }}
        transition={TRANSITION}
      >
        {currentAnimationCode === "HOFF" ? (
          <div className="tw-absolute -tw-right-5 tw-h-2 tw-w-2 tw-rounded-full tw-bg-background" />
        ) : null}
        {currentAnimationCode === "AOFF" ? (
          <div className="tw-absolute -tw-left-5 tw-h-2 tw-w-2 tw-rounded-full tw-bg-background" />
        ) : null}
        <div
          className={cn(
            "tw-flex tw-items-center",
            isHome ? "tw-flex-row-reverse" : "tw-flex-row"
          )}
        >
          <div className="tw-mx-1 tw-h-9 tw-w-[2px] tw-rounded-full tw-bg-background tw-opacity-25" />
          <div className={cn(isHome ? "tw-text-right" : "tw-text-left")}>
            <p className="tw-text-xs tw-text-white tw-opacity-80">
              {overlayText}
            </p>
            <div
              className={cn(
                "tw-flex tw-items-center",
                isHome ? "tw-flex-row-reverse" : "tw-flex-row"
              )}
            >
              <p className="tw-overflow-hidden tw-truncate tw-text-sm tw-text-white">
                {teamName}
              </p>
            </div>
          </div>
        </div>
      </motion.div>
      <RenderAnimationPlay teamColour={teamColour} />
      <OverlayPoly animationCode={currentAnimationCode} />
    </motion.div>
  );
}

function RenderAnimationPlay({
  currentAnimationCode,
  teamColour,
}: {
  teamColour: string;
  currentAnimationCode?: string;
}): JSX.Element | null {
  switch (currentAnimationCode) {
    case "ACOR":
      return <ACOR animationCode={currentAnimationCode} />;
    case "ATHR":
      return <ATHR animationCode={currentAnimationCode} />;
    case "AGKK":
      return (
        <AGKK animationCode={currentAnimationCode} teamColour={teamColour} />
      );
    case "AFOU":
      return (
        <AFOU animationCode={currentAnimationCode} teamColour={teamColour} />
      );
    case "AGOL":
      return <Goal />;
    case "HCOR":
      return <HCOR animationCode={currentAnimationCode} />;
    case "HTHR":
      return <HTHR animationCode={currentAnimationCode} />;
    case "HGKK":
      return (
        <HGKK animationCode={currentAnimationCode} teamColour={teamColour} />
      );
    case "HFOU":
      return (
        <HFOU animationCode={currentAnimationCode} teamColour={teamColour} />
      );
    case "HGOL":
      return <Goal />;
    default:
      return null;
  }
}

function OverlayPoly({
  animationCode,
}: {
  animationCode: string | null;
}): JSX.Element | null {
  if (!animationCode) return null;
  return (
    <AnimatePresence>
      {POLY_CODES.includes(animationCode) ? (
        <motion.div
          key="child"
          className={cn(
            "tw-absolute tw-inset-0 tw-z-10 tw-h-full tw-w-full tw-from-[#043600D4] tw-to-[#043100A6]",
            SOCCER_HOME_CODES.includes(animationCode) && "tw-bg-gradient-to-r",
            SOCCER_AWAY_CODES.includes(animationCode) && "tw-bg-gradient-to-l"
          )}
          variants={POLYGONE_VARIANTS}
          initial={{ width: "0%" }}
          animate={animationCode}
          exit={{ width: "0%" }}
          transition={TRANSITION}
        />
      ) : null}
    </AnimatePresence>
  );
}

function ACOR({ animationCode }: { animationCode: string }): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "ACOR" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            rotate: -10,
            originX: 0,
            originY: 0,
            opacity: 0,
            top: 3,
            left: 7,
          }}
          exit={{ opacity: 0 }}
          animate={{ rotate: 25, opacity: 1, top: 1, left: 4 }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4L100 45C100 45 100 100 45 100 "
              fill="url(#linear-gradient)"
            />
            <linearGradient id="linear-gradient" gradientTransform="rotate(45)">
              <stop stopColor="#053E00" stopOpacity="95%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="65%" offset="55%" />
              <stop stopColor="#053E00" stopOpacity="15%" offset="95%" />
            </linearGradient>
            <circle cx="4" cy="4" r="3" className="tw-fill-white tw-stroke-1" />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function ATHR({ animationCode }: { animationCode: string }): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "ATHR" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            rotate: 0,
            originX: 1,
            originY: 1,
            opacity: 0,
            bottom: 0,
            right: "35%",
          }}
          exit={{ opacity: 0 }}
          animate={{ rotate: 25, opacity: 1, bottom: 0, right: "35%" }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M95 95L0 50C0 50 0 0 50 0 " fill="url(#linear-gradient)" />
            <linearGradient id="linear-gradient" gradientTransform="rotate(45)">
              <stop stopColor="#053E00" stopOpacity="5%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="75%" offset="85%" />
              <stop stopColor="#053E00" stopOpacity="95%" offset="95%" />
            </linearGradient>
            <circle
              cx="97"
              cy="97"
              r="3"
              className="tw-fill-white tw-stroke-1"
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function AGKK({
  animationCode,
  teamColour,
}: {
  animationCode: string;
  teamColour: string;
}): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "AGKK" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            opacity: 0,
            top: "25%",
            originX: 1,
            originY: 0.5,
            right: 4,
            scale: 0.1,
          }}
          exit={{ opacity: 0 }}
          animate={{
            scale: 1.5,
            opacity: 1,
            top: "25%",
            right: 12,
            originX: 1,
            originY: 0.5,
          }}
          transition={TRANSITION}
        >
          <svg
            width="120"
            height="70"
            viewBox="0 0 120 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M113 35L30 70C30 70 -10 35 30 0"
              fill="url(#linear-gradient)"
            />
            <linearGradient id="linear-gradient" gradientTransform="rotate(0)">
              <stop stopColor="#053E00" stopOpacity="5%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="75%" offset="85%" />
              <stop stopColor="#053E00" stopOpacity="95%" offset="95%" />
            </linearGradient>
            <circle
              cx="116"
              cy="35"
              r="3"
              className="tw-stroke-white tw-stroke-1"
              style={{ fill: teamColour }}
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function AFOU({
  animationCode,
  teamColour,
}: {
  animationCode: string;
  teamColour: string;
}): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "AFOU" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            opacity: 0,
            top: "0%",
            originX: 1,
            originY: 0.5,
            right: "0%",
            scale: 0.1,
          }}
          exit={{ opacity: 0 }}
          animate={{
            opacity: 1,
            top: "50%",
            right: "30%",
            originX: 1,
            originY: 0.5,
            scale: 1,
          }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M93 50L20 100C20 100 -20 50 20 0"
              className="tw-fill-black/70"
            />
            <circle
              cx="95"
              cy="50"
              r="4"
              className="tw-stroke-white tw-stroke-1"
              style={{ fill: teamColour }}
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function HCOR({ animationCode }: { animationCode: string }): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "HCOR" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            rotate: -10,
            originX: 1,
            originY: 1,
            opacity: 0,
            bottom: 4,
            right: 7,
          }}
          exit={{ opacity: 0 }}
          animate={{ rotate: 25, opacity: 1, bottom: 1, right: 3 }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M95 95L0 55C0 55 0 0 55 0 " fill="url(#LinearGradient)" />
            <linearGradient id="LinearGradient" gradientTransform="rotate(40)">
              <stop stopColor="#053E00" stopOpacity="5%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="65%" offset="75%" />
              <stop stopColor="#053E00" stopOpacity="95%" offset="95%" />
            </linearGradient>
            <circle
              cx="96"
              cy="96"
              r="3"
              className="tw-fill-white tw-stroke-1"
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function HTHR({ animationCode }: { animationCode: string }): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "HTHR" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            rotate: -10,
            originX: 0,
            originY: 0,
            opacity: 0,
            top: 0,
            left: "30%",
          }}
          exit={{ opacity: 0 }}
          animate={{ rotate: 25, opacity: 1, top: 0, left: "35%" }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 4L100 50C100 50 100 100 50 100 "
              fill="url(#LinearGradient)"
            />
            <linearGradient id="LinearGradient" gradientTransform="rotate(45)">
              <stop stopColor="#053E00" stopOpacity="95%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="15%" offset="85%" />
              <stop stopColor="#053E00" stopOpacity="10%" offset="95%" />
            </linearGradient>
            <circle cx="3" cy="3" r="3" className="tw-fill-white tw-stroke-1" />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function HGKK({
  animationCode,
  teamColour,
}: {
  animationCode: string;
  teamColour: string;
}): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "HGKK" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            opacity: 0,
            top: "40%",
            originX: 0,
            originY: 0.5,
            left: 4,
            scale: 0.1,
          }}
          exit={{ opacity: 0 }}
          animate={{
            scale: 1.5,
            opacity: 1,
            top: "40%",
            left: 12,
            originX: 0,
            originY: 0.5,
          }}
          transition={TRANSITION}
        >
          <svg
            width="120"
            height="70"
            viewBox="0 0 120 70"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 35L90 70C90 70 130 35 90 0"
              fill="url(#LinearGradient)"
            />
            <linearGradient id="LinearGradient" gradientTransform="rotate(0)">
              <stop stopColor="#053E00" stopOpacity="95%" offset="0%" />
              <stop stopColor="#053E00" stopOpacity="25%" offset="85%" />
              <stop stopColor="#053E00" stopOpacity="10%" offset="95%" />
            </linearGradient>
            <circle
              cx="4"
              cy="35"
              r="3"
              className="tw-stroke-white tw-stroke-1"
              style={{ fill: teamColour }}
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function HFOU({
  animationCode,
  teamColour,
}: {
  animationCode: string;
  teamColour: string;
}): JSX.Element {
  return (
    <AnimatePresence>
      {animationCode === "HFOU" ? (
        <motion.div
          key="child"
          className="tw-absolute"
          initial={{
            opacity: 0,
            top: "0%",
            originX: 0,
            originY: 0.5,
            scale: 0.1,
            left: "30%",
          }}
          exit={{ opacity: 0 }}
          animate={{
            opacity: 1,
            top: "50%",
            left: "30%",
            originX: 0,
            originY: 0.5,
            scale: 1,
          }}
          transition={TRANSITION}
        >
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5 50L80 100C80 100 120 50 80 0"
              className="tw-fill-black/70"
            />
            <circle
              cx="5"
              cy="50"
              r="4"
              className="tw-stroke-white tw-stroke-1"
              style={{ fill: teamColour }}
            />
          </svg>
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function GameState(): JSX.Element | null {
  const {
    socketData: { gameStateIndex, gameState },
  } = GamesocketProvider.useGamesocket() as { socketData: SoccerEvent };
  if (gameStateIndex === null) return null;

  return (
    <AnimatePresence>
      {SOCCER_NOT_RUNNING.includes(gameStateIndex) ? (
        <motion.div
          key="child"
          className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-[#00000085]"
          initial={{ width: "100%", height: "0%" }}
          animate={{ width: "100%", height: "100%" }}
          exit={{ width: "100%", height: "0%" }}
          transition={TRANSITION}
        >
          <div className="tw-mb-4">
            <Whistle />
            <p className="tw-text-base tw-text-white md:tw-text-2xl">
              {parseGameState(gameStateIndex, gameState)}
            </p>
          </div>

          <ScoreBanner />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

interface GoalProps {
  currentAnimationCode?: string;
}

function Goal({ currentAnimationCode }: GoalProps): JSX.Element {
  return (
    <AnimatePresence>
      {currentAnimationCode === "HGOL" || currentAnimationCode === "AGOL" ? (
        <motion.div
          initial={{ width: "100%", height: "0%" }}
          animate={{ width: "100%", height: "100%" }}
          exit={{ width: "100%", height: "0%" }}
          className="tw-absolute tw-left-0 tw-top-0 tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-bg-[#00000085]"
        >
          <div className="tw-mb-4 tw-flex tw-items-center">
            <SoccerBall />
            <p className="tw-ml-2 tw-text-lg tw-font-extrabold tw-text-white md:tw-text-2xl">
              GOOOOALLLLL!
            </p>
          </div>
          <ScoreBanner />
        </motion.div>
      ) : null}
    </AnimatePresence>
  );
}

function SoccerBall(): JSX.Element {
  return (
    <svg
      width="45"
      height="45"
      viewBox="0 0 45 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 0.5C10.3715 0.5 0.5 10.3715 0.5 22.5C0.5 34.6285 10.3715 44.5 22.5 44.5C34.6285 44.5 44.5 34.6285 44.5 22.5C44.5 10.3715 34.6285 0.5 22.5 0.5ZM16.3913 5.22656L22.5 9.66667L28.6087 5.22656C31.9876 6.41726 34.9038 8.56068 37.0413 11.346L34.7067 18.5361L40.8083 22.9691C40.7155 26.6471 39.5608 30.0521 37.6178 32.8805H30.041L27.7064 40.0742C26.0557 40.5603 24.3109 40.8333 22.5 40.8333C20.6891 40.8333 18.9443 40.5603 17.2936 40.0742L14.9554 32.8841H7.38574C5.44112 30.0549 4.28455 26.6489 4.19173 22.9691L10.2969 18.5326L7.95866 11.346C10.0963 8.56068 13.0124 6.41726 16.3913 5.22656ZM22.5 13.3333L13.8239 19.6354L17.1396 29.8333H27.8604L31.1761 19.6354L22.5 13.3333Z"
        className="tw-fill-white"
      />
    </svg>
  );
}

function ScoreBanner(): JSX.Element {
  const { competitors } = CompetitorProvider.useCompetitors();
  const { trackedStats } = TrackedStatsProvider.useTrackedStats();
  const homeTeam = competitors["home"];
  const awayTeam = competitors["away"];
  const homeStats = trackedStats["home"];
  const awayStats = trackedStats["away"];
  return (
    <div className="tw-absolute tw-bottom-0 tw-left-0 tw-flex tw-w-full tw-items-center tw-bg-accent tw-font-semibold">
      <div className="tw-flex tw-flex-1 tw-items-center tw-justify-end tw-px-2">
        <p className="tw-mt-0.5 tw-text-[14px] tw-text-accent-foreground">
          {homeTeam?.name ?? ""}
        </p>
      </div>
      <div className="tw-mt-0.5 tw-flex tw-items-center tw-text-[24px] tw-text-accent-foreground">
        <p>{homeStats?.stats.goal ?? 0}</p>
        <span className="tw-mx-2">-</span>
        <p>{awayStats?.stats.goal ?? 0}</p>
      </div>
      <div className="tw-flex tw-flex-1 tw-items-center tw-justify-start tw-px-2">
        <p className="tw-mt-0.5 tw-text-[14px] tw-text-accent-foreground">
          {awayTeam?.name ?? ""}
        </p>
      </div>
    </div>
  );
}

function Whistle(): JSX.Element {
  return (
    <svg
      width="73"
      height="47"
      viewBox="0 0 73 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_ddd_385_140824)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M63.7866 19.6141C64.2296 19.5459 64.6437 19.85 64.7118 20.2925C64.78 20.7359 64.4765 21.1504 64.0335 21.2185C63.591 21.2866 63.1773 20.9825 63.109 20.5391C63.0409 20.0966 63.3441 19.6822 63.7866 19.6141ZM61.3599 17.9353L62.2235 23.5479L64.8429 22.5655C65.7172 22.2366 66.1927 21.1495 66.0296 20.0897C65.8666 19.0304 65.0862 18.1361 64.1537 18.0865L61.3599 17.9353Z"
          fill="#001041"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.8537 7.0356L43.81 7.03812L35.9343 8.25L36.0714 9.14121L30.0966 10.0606L29.9595 9.16936L6.5 12.7792L7.92226 19.1136L30.411 23.0042C30.4179 23.7689 30.4768 24.542 30.5967 25.3213C31.9459 34.0895 40.1475 40.1029 48.914 38.7539C57.6822 37.4047 63.6962 29.2039 62.347 20.4357C60.998 11.6688 52.7968 5.65528 44.0286 7.00448C43.97 7.01351 43.9125 7.02487 43.8537 7.0356Z"
          fill="#F3F3F3"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M56.3535 21.3583C57.1375 26.4538 53.6434 31.2192 48.5475 32.0033C43.4533 32.7872 38.687 29.2928 37.9029 24.1974C37.119 19.1031 40.6142 14.3367 45.7085 13.5528C50.8043 12.7687 55.5696 16.2641 56.3535 21.3583Z"
          fill="#001041"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M49.9547 22.3429C50.1951 23.9051 49.1245 25.3651 47.5628 25.6054C46.0014 25.8456 44.5406 24.7752 44.3002 23.213C44.0601 21.6521 45.1313 20.1912 46.6927 19.9509C48.2545 19.7106 49.7145 20.782 49.9547 22.3429Z"
          fill="#F3F3F3"
        />
      </g>
      <defs>
        <filter
          id="filter0_ddd_385_140824"
          x="0.5"
          y="0.815308"
          width="71.5615"
          height="46.1277"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-1" />
          <feGaussianBlur stdDeviation="1.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0 0.254902 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_385_140824"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0 0.254902 0 0 0 0.12 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_385_140824"
            result="effect2_dropShadow_385_140824"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="3" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.0627451 0 0 0 0 0.254902 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_385_140824"
            result="effect3_dropShadow_385_140824"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect3_dropShadow_385_140824"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

function parseGameState(gameStateIndex: number, gameState: string): string {
  if (gameStateIndex === 0) return "Pre-game";
  else if (gameStateIndex === 6) return "Full Time";
  return gameState;
}
